// import React from "react";
// import {Dropdown} from "react-bootstrap";
// import { DefinedRange, createStaticRanges } from 'react-date-range';
// import { useState, useEffect } from 'react';
// import axios from "axios";
// import moment from "moment";

// import {DropdownCustomToggler} from "../dropdowns";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { LoadingOverlay, Loader } from 'react-overlay-loader';

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

// import {
//   addDays,
//   endOfDay,
//   startOfDay,
//   startOfMonth,
//   endOfMonth,
//   addMonths,
//   startOfWeek,
//   endOfWeek,
//   isSameDay,
//   differenceInCalendarDays,
// } from 'date-fns';

// export const DASHBOARD_URL = "adminDashboard";

// export function Demo1Dashboard() {

//     const [dashboardStats, setDashboardStats] = useState(false);
//     const [loading, setLoader] = useState(false);
//     const [hasError, setErrors] = useState(false);

//     async function fetchData(dateRange) {
//       setLoader(true);
//       let paramsArray = JSON.stringify({"startDate": moment(dateRange?.startDate).format('YYYY-MM-D'), "endDate": moment(dateRange?.endDate).format('YYYY-MM-D')});
//       console.log("paramsArray: ", paramsArray);
//       axios.post(DASHBOARD_URL, { paramsArray })
//       .then(response => {
//             console.log(response.data);
//             setDashboardStats(response.data.data);
//             setLoader(false);
//           //set state object there...
//         })
//         .catch(error => {
//           setErrors(error);
//           setLoader(false);
//       });
//     }
//     useEffect(() => {
//       fetchData({"startDate": startOfDay(addDays(new Date(), -7)), "endDate": endOfDay(new Date())});
//   }, []  );

//     const rangesArr = [
//         ...createStaticRanges([
//             {
//                 label: 'Today',
//                 range: () => ({
//                     startDate: startOfDay(new Date()),
//                     endDate: endOfDay(new Date()),
//                 })
//             },
//             {
//                 label: 'Last 7 Days',
//                 range: () => ({
//                     startDate: startOfDay(addDays(new Date(), -7)),
//                     endDate: endOfDay(new Date()),
//                 }),
//             },
//             {
//                 label: 'Last 30 Days',
//                 range: () => ({
//                     startDate: startOfDay(addDays(new Date(), -30)),
//                     endDate: endOfDay(new Date()),
//                 }),
//             },
//             {
//                 label: 'Last 90 Days',
//                 range: () => ({
//                     startDate: startOfDay(addDays(new Date(), -90)),
//                     endDate: endOfDay(new Date()),
//                 }),
//             },
//             {
//                 label: 'Last Year',
//                 range: () => ({
//                     startDate: startOfDay(addDays(new Date(), -365)),
//                     endDate: endOfDay(new Date()),
//                 }),
//             }
//         ])
//     ];

//     const [state, setState] = useState([
//       {
//         startDate: startOfDay(addDays(new Date(), -7)),
//         endDate: endOfDay(new Date()),
//         key: 'selection'
//       }
//     ]);

//     const rangeChange = (item) => {
//       console.log("date range: ", item);
//       setState([item.selection]);
//       fetchData(item.selection);
//     };

//     const dateRangeText = () => {
//         if(moment(state[0]?.startDate).year() === moment(state[0]?.endDate).year()) {
//             if(moment(state[0]?.startDate).month() === moment(state[0]?.endDate).month()) {
//                 return moment(state[0]?.startDate).format('MMM D')  + '-' +  moment(state[0]?.endDate).format('D, YYYY');
//             }
//             else{
//                 return moment(state[0]?.startDate).format('MMM D')  + '-' +  moment(state[0]?.endDate).format('MMM D, YYYY');
//             }
//         }
//         else
//             return moment(state[0]?.startDate).format('MMM D, YYYY')  + '-' +  moment(state[0]?.endDate).format('MMM D, YYYY');
//     };
//     console.log(state);

//     const statsWidget = (className, iconName, color, widgetTitle, count) => {
//         return (
//           <div className={`card card-custom bg-white mt-4 ${className}`}>
//             {/* Body */}
//             <div className="card-body position-relative overflow-hidden">
//               <div className="row justify-content-center">
//                 <div className="col-md-4 my-auto">
//                 <i style={{ color: color, 'fontSize': '3em' }} className={`fa fa-${iconName}`}></i>

//                 </div>
//                 <div className="col-md-8">
//                   <div className="row">
//                     <h4>{widgetTitle}</h4>
//                   </div>
//                   <div className="row">
//                     <h1>{count}</h1>
//                   </div>
//                 </div>
//               </div>

//             </div>
//           </div>
//         );
//     }
//     return (<>
//             <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222' }}>

//                 <Loader loading={loading}/>
//             </LoadingOverlay>
//             <div className="row">
//                 <div className="col-md-12">
//                 <Dropdown className="dropdown-inline calendarbox" alignRight>
//                   <Dropdown.Toggle
//                     className="btn btn-color-primary btn-clean btn-hover-light-primary btn-icon"
//                     variant="transparent"
//                     id="dropdown-toggle-top"
//                     as={DropdownCustomToggler}
//                    >
//                     <i className="ki ki-calendar btn btn-color-primary btn-active-light-primary" />
//                     { dateRangeText() }
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
//                       <DefinedRange
//                           onChange={item => rangeChange(item)}
//                           ranges={state }
//                           staticRanges= {rangesArr}
//                       />
//                   </Dropdown.Menu>
//                 </Dropdown>

//                 </div>
//             </div>


// <div className="container">
//     <div className="row d-flex justify-content-center">
//         <div className="col-md-5 col-xxl-6">
//             <div className="row bg-light">
//                 <div className="col-md-6 col-xxl-6">
//                     {statsWidget("card-stretch gutter-b", 'calendar-plus', "#FF8900", 'Total Events', dashboardStats ? dashboardStats.totalEvent : 0)}
//                 </div>
//                 <div className="col-lg-6 col-xxl-6">
//                     {statsWidget("card-stretch gutter-b", 'comment-dollar', "#BE2EB5", 'Total Revenue Earning', dashboardStats ? `$${dashboardStats.totalRevenue}` : '$0')}
//                 </div>
//             </div>
//             <div className="row bg-light">
//                 <div className="col-md-6 col-xxl-6">
//                     {statsWidget("card-stretch gutter-b", 'credit-card', "#BE2EB5", 'Total Tickets Sold', dashboardStats ? dashboardStats.ticketSold : 0)}
//                 </div>
//                 <div className="col-md-6 col-xxl-6">
//                     {statsWidget("card-stretch gutter-b", 'users', "#BE2EB5", 'Total Engagement', dashboardStats ? dashboardStats.totalEngage : 0)}
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>


//     </>);
// }
/////////////////////////////////////////////////////////////////Flow chart graph////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from 'react';
// import { Dropdown } from 'react-bootstrap';
// import { DefinedRange, createStaticRanges } from 'react-date-range';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import axios from 'axios';
// import moment from 'moment';

// import { DropdownCustomToggler } from '../dropdowns';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { LoadingOverlay, Loader } from 'react-overlay-loader';

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

// import {
//   addDays,
//   endOfDay,
//   startOfDay,
//   startOfMonth,
//   endOfMonth,
//   addMonths,
//   startOfWeek,
//   endOfWeek,
//   isSameDay,
//   differenceInCalendarDays,
// } from 'date-fns';

// export const DASHBOARD_URL = 'adminDashboard';

// export function Demo1Dashboard() {
//   const [dashboardStats, setDashboardStats] = useState(false);
//   const [loading, setLoader] = useState(false);
//   const [hasError, setErrors] = useState(false);

//   async function fetchData(dateRange) {
//     setLoader(true);
//     let paramsArray = JSON.stringify({
//       startDate: moment(dateRange?.startDate).format('YYYY-MM-D'),
//       endDate: moment(dateRange?.endDate).format('YYYY-MM-D'),
//     });
//     console.log('paramsArray: ', paramsArray);
//     axios
//       .post(DASHBOARD_URL, { paramsArray })
//       .then((response) => {
//         console.log(response.data);
//         setDashboardStats(response.data.data);
//         setLoader(false);
//         //set state object there...
//       })
//       .catch((error) => {
//         setErrors(error);
//         setLoader(false);
//       });
//   }
//   useEffect(() => {
//     fetchData({
//       startDate: startOfDay(addDays(new Date(), -7)),
//       endDate: endOfDay(new Date()),
//     });
//   }, []);

//   const rangesArr = [
//     ...createStaticRanges([
//       {
//         label: 'Today',
//         range: () => ({
//           startDate: startOfDay(new Date()),
//           endDate: endOfDay(new Date()),
//         }),
//       },
//       {
//         label: 'Last 7 Days',
//         range: () => ({
//           startDate: startOfDay(addDays(new Date(), -7)),
//           endDate: endOfDay(new Date()),
//         }),
//       },
//       {
//         label: 'Last 30 Days',
//         range: () => ({
//           startDate: startOfDay(addDays(new Date(), -30)),
//           endDate: endOfDay(new Date()),
//         }),
//       },
//       {
//         label: 'Last 90 Days',
//         range: () => ({
//           startDate: startOfDay(addDays(new Date(), -90)),
//           endDate: endOfDay(new Date()),
//         }),
//       },
//       {
//         label: 'Last Year',
//         range: () => ({
//           startDate: startOfDay(addDays(new Date(), -365)),
//           endDate: endOfDay(new Date()),
//         }),
//       },
//     ]),
//   ];

//   const [state, setState] = useState([
//     {
//       startDate: startOfDay(addDays(new Date(), -7)),
//       endDate: endOfDay(new Date()),
//       key: 'selection',
//     },
//   ]);

//   const rangeChange = (item) => {
//     console.log('date range: ', item);
//     setState([item.selection]);
//     fetchData(item.selection);
//   };

//   const dateRangeText = () => {
//     if (moment(state[0]?.startDate).year() === moment(state[0]?.endDate).year()) {
//       if (moment(state[0]?.startDate).month() === moment(state[0]?.endDate).month()) {
//         return (
//           moment(state[0]?.startDate).format('MMM D') +
//           '-' +
//           moment(state[0]?.endDate).format('D, YYYY')
//         );
//       } else {
//         return (
//           moment(state[0]?.startDate).format('MMM D') +
//           '-' +
//           moment(state[0]?.endDate).format('MMM D, YYYY')
//         );
//       }
//     } else
//       return (
//         moment(state[0]?.startDate).format('MMM D, YYYY') +
//         '-' +
//         moment(state[0]?.endDate).format('MMM D, YYYY')
//       );
//   };
//   console.log(state);

//   const statsWidget = (className, iconName, color, widgetTitle, count) => {
//     return (
//       <div className={`card card-custom bg-white mt-4 ${className}`}>
//         {/* Body */}
//         <div className="card-body position-relative overflow-hidden">
//           <div className="row justify-content-center">
//             <div className="col-md-4 my-auto">
//               <i style={{ color: color, fontSize: '3em' }} className={`fa fa-${iconName}`} />
//             </div>
//             <div className="col-md-8">
//               <div className="row">
//                 <h4>{widgetTitle}</h4>
//               </div>
//               <div className="row">
//                 <h1>{count}</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <>
//       <LoadingOverlay style={{ width: '100%', height: 'auto', backgroundColor: '#222222' }}>
//         <Loader loading={loading} />
//       </LoadingOverlay>
//       <div className="row">
//         <div className="col-md-12">
//           <Dropdown className="dropdown-inline calendarbox" alignRight>
//             <Dropdown.Toggle
//               className="btn btn-color-primary btn-clean btn-hover-light-primary btn-icon"
//               variant="transparent"
//               id="dropdown-toggle-top"
//               as={DropdownCustomToggler}
//             >
//               <i className="ki ki-calendar btn btn-color-primary btn-active-light-primary" />
//               {dateRangeText()}
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
//               <DefinedRange
//                 onChange={(item) => rangeChange(item)}
//                 ranges={state}
//                 staticRanges={rangesArr}
//               />
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//       </div>
//       <div className="container">
//         <div className="row d-flex justify-content-center">
//           <div className="col-md-5 col-xxl-6">
//             <div className="row bg-light">
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget(
//                   'card-stretch gutter-b',
//                   'calendar-plus',
//                   '#FF8900',
//                   'Total Events',
//                   dashboardStats ? dashboardStats.totalEvent : 0
//                 )}
//               </div>
//               <div className="col-lg-6 col-xxl-6">
//                 {statsWidget(
//                   'card-stretch gutter-b',
//                   'comment-dollar',
//                   '#BE2EB5',
//                   'Total Revenue Earning',
//                   dashboardStats ? `$${dashboardStats.totalRevenue}` : '$0'
//                 )}
//               </div>
//             </div>
//             <div className="row bg-light">
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget(
//                   'card-stretch gutter-b',
//                   'credit-card',
//                   '#BE2EB5',
//                   'Total Tickets Sold',
//                   dashboardStats ? dashboardStats.ticketSold : 0
//                 )}
//               </div>
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget(
//                   'card-stretch gutter-b',
//                   'users',
//                   '#BE2EB5',
//                   'Total Engagement',
//                   dashboardStats ? dashboardStats.totalEngage : 0
//                 )}
//               </div>
//             </div>
//           </div>
//           {/* Recharts Bar Chart */}
//           <div className="col-md-5 col-xxl-6">
//             <h2>Sales Overview</h2>
//             {dashboardStats && (
//               <BarChart width={600} height={400} data={dashboardStats.month}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="month" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Bar dataKey="total_amount" fill="#8884d8" />
//               </BarChart>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
////////////////////////////////////////////////////////////////simple wave graph/////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from 'react';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';
// import { Dropdown } from 'react-bootstrap';
// import { DefinedRange } from 'react-date-range';


// import axios from 'axios';
// import moment from 'moment';

// import { DropdownCustomToggler } from '../dropdowns';
// import { LoadingOverlay, Loader } from 'react-overlay-loader';
// import 'react-overlay-loader/styles.css';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';

// import { startOfDay, addDays, endOfDay } from 'date-fns';

// export const DASHBOARD_URL = "adminDashboard";

// export function Demo1Dashboard() {
//   const [dashboardStats, setDashboardStats] = useState(false);
//   const [loading, setLoader] = useState(false);
//   const [hasError, setErrors] = useState(false);

//   async function fetchData(dateRange) {
//     setLoader(true);
//     let paramsArray = JSON.stringify({
//       "startDate": moment(dateRange?.startDate).format('YYYY-MM-D'),
//       "endDate": moment(dateRange?.endDate).format('YYYY-MM-D')
//     });
  
//     console.log("paramsArray: ", paramsArray);
  
//     try {
//       const response = await axios.post(DASHBOARD_URL, { paramsArray });
//       console.log("Data from the server:", response.data);
  
//       // Check if the data property exists in the response
//       if (response.data.data && response.data.data.month) {
//         setDashboardStats({ salesOverview: response.data.data.month });
//       } else {
//         console.error("Invalid data structure received from the server.");
//       }
  
//       setLoader(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setErrors(error);
//       setLoader(false);
//     }
//   }
  

//   useEffect(() => {
//     fetchData({ "startDate": startOfDay(addDays(new Date(), -7)), "endDate": endOfDay(new Date()) });
//   }, []);

//   const rangesArr = [
//     {
//       label: 'Today',
//       range: () => ({
//         startDate: startOfDay(new Date()),
//         endDate: endOfDay(new Date()),
//       }),
//     },
//     {
//       label: 'Last 7 Days',
//       range: () => ({
//         startDate: startOfDay(addDays(new Date(), -7)),
//         endDate: endOfDay(new Date()),
//       }),
//     },
//     {
//       label: 'Last 30 Days',
//       range: () => ({
//         startDate: startOfDay(addDays(new Date(), -30)),
//         endDate: endOfDay(new Date()),
//       }),
//     },
//     {
//       label: 'Last 90 Days',
//       range: () => ({
//         startDate: startOfDay(addDays(new Date(), -90)),
//         endDate: endOfDay(new Date()),
//       }),
//     },
//     {
//       label: 'Last Year',
//       range: () => ({
//         startDate: startOfDay(addDays(new Date(), -365)),
//         endDate: endOfDay(new Date()),
//       }),
//     }
//   ];

//   const [state, setState] = useState([
//     {
//       startDate: startOfDay(addDays(new Date(), -7)),
//       endDate: endOfDay(new Date()),
//       key: 'selection'
//     }
//   ]);

//   const rangeChange = (item) => {
//     console.log("date range: ", item);
//     setState([item.selection]);
//     fetchData(item.selection);
//   };

//   const dateRangeText = () => {
//     if (moment(state[0]?.startDate).year() === moment(state[0]?.endDate).year()) {
//       if (moment(state[0]?.startDate).month() === moment(state[0]?.endDate).month()) {
//         return moment(state[0]?.startDate).format('MMM D') + '-' + moment(state[0]?.endDate).format('D, YYYY');
//       }
//       else {
//         return moment(state[0]?.startDate).format('MMM D') + '-' + moment(state[0]?.endDate).format('MMM D, YYYY');
//       }
//     }
//     else
//       return moment(state[0]?.startDate).format('MMM D, YYYY') + '-' + moment(state[0]?.endDate).format('MMM D, YYYY');
//   };

//   const statsWidget = (className, iconName, color, widgetTitle, count) => {
//     return (
//       <div className={`card card-custom bg-white mt-4 ${className}`}>
//         <div className="card-body position-relative overflow-hidden">
//           <div className="row justify-content-center">
//             <div className="col-md-4 my-auto">
//               <i style={{ color: color, 'fontSize': '3em' }} className={`fa fa-${iconName}`}></i>
//             </div>
//             <div className="col-md-8">
//               <div className="row">
//                 <h4>{widgetTitle}</h4>
//               </div>
//               <div className="row">
//                 <h1>{count}</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <>
//       <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222' }}>
//         <Loader loading={loading} />
//       </LoadingOverlay>

//       <div className="row">
//         <div className="col-md-12">
//           <Dropdown className="dropdown-inline calendarbox" alignRight>
//             <Dropdown.Toggle
//               className="btn btn-color-primary btn-clean btn-hover-light-primary btn-icon"
//               variant="transparent"
//               id="dropdown-toggle-top"
//               as={DropdownCustomToggler}
//             >
//               <i className="ki ki-calendar btn btn-color-primary btn-active-light-primary" />
//               {dateRangeText()}
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
//               <DefinedRange
//                 onChange={item => rangeChange(item)}
//                 ranges={state}
//                 staticRanges={rangesArr}
//               />
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//       </div>

//       <div className="container">
//         <div className="row d-flex justify-content-center">
//           <div className="col-md-5 col-xxl-6">
//             <div className="row bg-light">
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'calendar-plus', "#FF8900", 'Total Events', dashboardStats ? dashboardStats.totalEvent : 0)}
//               </div>
//               <div className="col-lg-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'comment-dollar', "#BE2EB5", 'Total Revenue Earning', dashboardStats ? `$${dashboardStats.totalRevenue}` : '$0')}
//               </div>
//             </div>
//             <div className="row bg-light">
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'credit-card', "#BE2EB5", 'Total Tickets Sold', dashboardStats ? dashboardStats.ticketSold : 0)}
//               </div>
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'users', "#BE2EB5", 'Total Engagement', dashboardStats ? dashboardStats.totalEngage : 0)}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-5 col-xxl-6">
//             <h2>Sales Overview</h2>
//             {dashboardStats && (
//               <LineChart width={500} height={300} data={dashboardStats.salesOverview}>
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
//                 <Tooltip />
//                 <Legend />
//                 <Line type="monotone" dataKey="total_amount" stroke="#8884d8" />
//               </LineChart>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
//////////////////////////////////////////////////////////Updated Curve Graph//////////////////////////////////////////////////////////////////
 
// import React, { useState, useEffect } from 'react';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   Area,
//   AreaChart,
// } from 'recharts';

// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';  // Import the styles

// import { Dropdown } from 'react-bootstrap';
// import { DefinedRange } from 'react-date-range';
// import { isSameDay } from 'date-fns';
// import numeral from 'numeral';
// import { Label } from 'recharts';

// import axios from 'axios';
// import moment from 'moment';

// import { DropdownCustomToggler } from '../dropdowns';
// import { LoadingOverlay, Loader } from 'react-overlay-loader';
// import 'react-overlay-loader/styles.css';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';

// import { startOfDay, addDays, endOfDay } from 'date-fns';

// export const DASHBOARD_URL = "adminDashboard";

// // const NotificationMessages = ({ notifications }) => {
// //   const [selectedMonth, setSelectedMonth] = useState(-1);
// //   const [selectedPageSize, setSelectedPageSize] = useState(-1);

// //   const filteredNotifications = selectedMonth !== -1
// //     ? notifications.filter(
// //         (notification) =>
// //           moment(notification.dateSent).month() + 1 === selectedMonth
// //       )
// //     : notifications;

// //   const slicedNotifications = selectedPageSize === -1
// //     ? filteredNotifications
// //     : filteredNotifications.slice(0, selectedPageSize);

// //   return (
// //     <div className="container d-flex justify-content-center">
// //       <div className="col-md-5 col-xxl-6">
// //         <h2>Activity Feed</h2>
// //         <label>Select Month: </label>
// //         <select onChange={(e) => setSelectedMonth(parseInt(e.target.value))}>
// //           <option value={-1}>All</option>
// //           {moment.months().map((month, index) => (
// //             <option key={index} value={index + 1}>
// //               {month}
// //             </option>
// //           ))}
// //         </select>

// //         <label>Select Page Size: </label>
// //         <select onChange={(e) => setSelectedPageSize(parseInt(e.target.value))}>
// //           <option value={-1}>All</option>
// //           <option value={10}>10</option>
// //           <option value={50}>50</option>
// //           <option value={100}>100</option>
// //           <option value={150}>150</option>
// //           <option value={200}>200</option>
// //         </select>

// //         {slicedNotifications.map((notification, index) => (
// //         <div key={notification.id} className="notification-item d-flex align-items-start" style={{ marginBottom: index === slicedNotifications.length - 1 ? 0 : '10px' }}>
// //         {/* Circular profile image on the left */}
// //         <img
// //           src={notification.sender.profilePic}
// //           alt="Profile"
// //           style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', flexShrink: 0 }}
// //         />
      
// //         {/* Display the message on the right */}
// //         <div>
// //           <p style={{ margin: 0, paddingBottom: '5px' }}>{notification.message}</p>
// //           <p style={{ margin: 0 }}>{moment(notification.dateSent).format('DD MMM YYYY HH:mm:ss')}</p>
// //         </div>
// //       </div>
      
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };
// const NotificationMessages = ({ notifications }) => {
//   const storedFilter = sessionStorage.getItem('selectedFilter');
//   const [selectedFilter, setSelectedFilter] = useState(storedFilter || 'all');
//   const [filteredNotifications, setFilteredNotifications] = useState(notifications);

//   const applyFilter = (filter) => {
//     if (filter === 'all') {
//       setFilteredNotifications(notifications);
//     } else {
//       const selectedMonth = parseInt(filter);
//       const filtered = notifications.filter((notification) => {
//         const notificationMonth = moment(notification.dateSent).month() + 1;
//         return notificationMonth === selectedMonth;
//       });
//       setFilteredNotifications(filtered);
//     }
//   };

//   const handleFilterChange = (event) => {
//     const newFilter = event.target.value;
//     setSelectedFilter(newFilter);
//     applyFilter(newFilter);
//   };

//   // Apply filter on initial load
//   useEffect(() => {
//     applyFilter(selectedFilter);
//   }, [selectedFilter, notifications]);

//   // Store selected filter in sessionStorage
//   useEffect(() => {
//     sessionStorage.setItem('selectedFilter', selectedFilter);
//   }, [selectedFilter]);

//   // Initialize the default filter if there's no stored value
//   useEffect(() => {
//     if (!storedFilter) {
//       setSelectedFilter('all');
//     }
//   }, [storedFilter]);

//   return (
//     <div style={{marginLeft:"40px"}}>
//       <div >
//         {/* Dropdown for filters with inline styles */}
//         <select
//           style={{
//             float: "right",
//             width: '200px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedFilter}
//           onChange={handleFilterChange}
//         >
//           <option value="all">All Activity</option>
//           {[...Array(12).keys()].map((month) => (
//             <option key={month} value={month + 1}>
//               {moment().month(month).format('MMMM')}
//             </option>
//           ))}
//         </select>

//         {/* Activity Feed heading */}
//         <h2 style={{ textAlign: 'left' }}>Activity Feed</h2>

//         {filteredNotifications.map((notification, index) => (
//           <div key={notification.id} className="notification-item d-flex align-items-start" style={{ marginBottom: index === filteredNotifications.length - 1 ? 0 : '10px',marginTop:"20px" }}>
//             {/* Circular profile image on the left */}
//             <img
//               src={notification.sender.profilePic}
//               alt="Profile"
//               style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', flexShrink: 0 }}
//             />

//             {/* Display the message on the right */}
//             <div>
//               <p style={{ margin: 0, paddingBottom: '5px' }}>{notification.message}</p>
//               <p style={{ margin: 0 }}>{moment(notification.dateSent).format('DD MMM YYYY HH:mm:ss')}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };



// // const RSVPChart = () => {
// //   // Dummy data
// //   const totalRSVPs = 10000;
// //   const acceptedRSVPs = 6500;
// //   const rejectedRSVPs = 3500;
// //   const acceptedPercentage = (acceptedRSVPs / totalRSVPs) * 100;
// //   const rejectedCount = 4647;

// //   return (
// //     <div style={{ textAlign: 'center', fontFamily: 'sans-serif', width: '200px', margin: 'auto' }}>
// //       <h2>RSVP Total Count</h2>
// //       <div style={{ width: '100px', margin: 'auto' }}>
// //         <CircularProgressbar
// //           value={acceptedPercentage}
// //           text={`${acceptedPercentage.toFixed(0)}%`}
// //           styles={buildStyles({
// //             textColor: '#007bff',
// //             pathColor: '#007bff',
// //             trailColor: '#eee',
// //             strokeWidth: 8, // Adjust this value to change the thickness of the progress bar
// //           })}
// //         />
// //       </div>
// //       <div style={{ marginTop: '10px' }}>
// //         <p>Total RSVPs: {totalRSVPs}</p>
// //         <p>Rejected: {rejectedCount}</p>
// //       </div>
// //     </div>
// //   );
// // };





// // const RSVPStatus = () => {
// //   // Dummy data
// //   const totalRSVPs = 4647;
// //   const acceptedPercentage = 65;
// //   const rejectedPercentage = 35;

// //   return (
// //     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px', margin: 'auto' }}>
// //       {/* Accepted */}
// //       <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
// //         <span role="img" aria-label="Ticket Mark" style={{ fontSize: '20px', marginRight: '5px' }}>
// //           🎫
// //         </span>
// //         <div>
// //           <strong>Accepted</strong>
// //           <div>{acceptedPercentage}%</div>
// //         </div>
// //       </div>

// //       {/* Rejected */}
// //       <div style={{ textAlign: 'left', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
// //         <span role="img" aria-label="Cross Mark" style={{ fontSize: '20px', marginRight: '5px' }}>
// //           ❌
// //         </span>
// //         <div>
// //           <strong>Rejected</strong>
// //           <div>{rejectedPercentage}%</div>
// //         </div>
// //       </div>

// //       {/* Total RSVPs */}
// //       <div style={{ textAlign: 'center', marginTop: '10px' }}>
// //         <CircularProgressbar
// //           value={acceptedPercentage}
// //           text={`RSVP Total\n${totalRSVPs}`}
// //           styles={buildStyles({
// //             textSize: '14px',
// //             textColor: '#333',
// //             pathColor: '#007bff',
// //             trailColor: '#eee',
// //             backgroundColor: '#fff',
// //           })}
// //         />
// //       </div>
// //     </div>
// //   );
// // };


// const RSVPStatus = ({ completed, pending, total }) => {
//   const completedPercentage = (completed / total) * 100;

//   return (
//     <div className='Heading' style={{ marginLeft: '50px' }}>
//       <h2>RSVP Total Count</h2>
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           width: '300px',
//           marginBottom: '80px',
//           marginLeft: '350px',
//         }}
//       >
//         <div style={{ textAlign: 'left', width: '100px', marginTop: '70px' }}>
//           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
//             <span role='img' aria-label='Check Mark' style={{ fontSize: '20px', marginRight: '5px' }}>
//               ✓
//             </span>
//             <div>
//               <strong>Completed</strong>
//               <div>{completed}%</div>
//             </div>
//           </div>

//           <div style={{ display: 'flex', alignItems: 'center' }}>
//             <span role='img' aria-label='Cross Mark' style={{ fontSize: '20px', marginRight: '5px' }}>
//               ❌
//             </span>
//             <div>
//               <strong>Pending</strong>
//               <div>{pending}%</div>
//             </div>
//           </div>
//         </div>

//         <div style={{ textAlign: 'center', width: '200px', marginLeft: '20px', marginTop: '60px',marginTop:"5px" }}>
//           <CircularProgressbar
//             value={completedPercentage}
//             // text={`${completed}%`}
//             styles={{
//               path: { stroke: '#007bff', transition: 'none' },
//               trail: { stroke: '#eee' },
//               background: { fill: '#fff' },
//               text: { fontSize: '10px', fill: '#333' },
//             }}
//             strokeWidth={12}
//           />
//           <div style={{ marginTop: '-120px', textAlign: 'center', fontSize: '18px', color: '#333' }}>
//             RSVP Total<br />
//             <b>{total}</b>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };


// export function Demo1Dashboard() {
//   const [dashboardStats, setDashboardStats] = useState(false);
//   const [loading, setLoader] = useState(false);
//   const [hasError, setErrors] = useState(false);
//   const [notifications, setNotifications] = useState([]); // Add this line
//   const [prizes, setPrizes] = useState({ total: 0, completed: 0, pending: 0 });

//   async function fetchData(dateRange) {
//     setLoader(true);
//     let paramsArray = JSON.stringify({
//       "startDate": moment(dateRange?.startDate).format('YYYY-MM-D'),
//       "endDate": moment(dateRange?.endDate).format('YYYY-MM-D')
//     });
  
//     console.log("paramsArray: ", paramsArray);
  
//     try {
//       const response = await axios.post(DASHBOARD_URL, { paramsArray });
//       console.log("Data from the server:", response.data);
  
//       // Check if the data property exists in the response
//      if (response.data.data && response.data.data.month) {
//   setDashboardStats({
//     salesOverview: response.data.data.month,
//     totalEvent: response.data.data.totalEvent,
//     totalRevenue: response.data.data.totalRevenue,
//     ticketSold: response.data.data.ticketSold,
//     totalEngage: response.data.data.totalEngage,
//   });  
  
  
  
//   // Set notifications here
//   setNotifications(response.data.data.notification || []);

//         // Set prizes data
//         setPrizes(response.data.data.prizes || { total: 0, completed: 0, pending: 0 });
//       } else {
//         console.error("Invalid data structure received from the server.");
//       }

// setLoader(false);
// } catch (error) {
// console.error("Error fetching data:", error);
// setErrors(error);
// setLoader(false);
// }
// }
  

//   useEffect(() => {
//     fetchData({ "startDate": startOfDay(addDays(new Date(), -7)), "endDate": endOfDay(new Date()) });
//   }, []);
  
// ////////////////////////////////////////////////Date Filter start//////////////////////////////////////////////
//   // const rangesArr = [
//   //   {
//   //     label: 'Today',
//   //     range: () => ({
//   //       startDate: new Date(),
//   //       endDate: new Date(),
//   //     }),
//   //     isSelected: (date) => isSameDay(date, new Date()),
//   //   },
//   //   {
//   //     label: 'Yesterday',
//   //     range: () => {
//   //       const yesterday = new Date();
//   //       yesterday.setDate(yesterday.getDate() - 1);
//   //       return {
//   //         startDate: yesterday,
//   //         endDate: yesterday,
//   //       };
//   //     },
//   //     isSelected: (date) => isSameDay(date, new Date(new Date().setDate(new Date().getDate() - 1))),
//   //   },
//   //   {
//   //     label: 'Last 7 Days',
//   //     range: () => ({
//   //       startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
//   //       endDate: new Date(),
//   //     }),
//   //     isSelected: (date) =>
//   //       isSameDay(date, new Date(new Date().setDate(new Date().getDate() - 7))) ||
//   //       isSameDay(date, new Date()),
//   //   },
//   //   {
//   //     label: 'Last 30 Days',
//   //     range: () => ({
//   //       startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
//   //       endDate: new Date(),
//   //     }),
//   //     isSelected: (date) =>
//   //       isSameDay(date, new Date(new Date().setDate(new Date().getDate() - 30))) ||
//   //       isSameDay(date, new Date()),
//   //   },
//   //   {
//   //     label: 'Last 90 Days',
//   //     range: () => ({
//   //       startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
//   //       endDate: new Date(),
//   //     }),
//   //     isSelected: (date) =>
//   //       isSameDay(date, new Date(new Date().setDate(new Date().getDate() - 90))) ||
//   //       isSameDay(date, new Date()),
//   //   },
//   //   {
//   //     label: 'Last Year',
//   //     range: () => ({
//   //       startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
//   //       endDate: new Date(),
//   //     }),
//   //     isSelected: (date) =>
//   //       isSameDay(date, new Date(new Date().setFullYear(new Date().getFullYear() - 1))) ||
//   //       isSameDay(date, new Date()),
//   //   },
//   //   // ... add more static ranges as needed
//   // ];
// ////////////////////////////////////////////////Date Filter End//////////////////////////////////////////////
  


//   ////////////////////////////////////////////////Date Filter start//////////////////////////////////////////////
//   // const [state, setState] = useState([
//   //   {
//   //     startDate: startOfDay(addDays(new Date(), -7)),
//   //     endDate: endOfDay(new Date()),
//   //     key: 'selection'
//   //   }
//   // ]);
//   ////////////////////////////////////////////////Date Filter End//////////////////////////////////////////////
// ////////////////////////////////////////////////Date Filter start//////////////////////////////////////////////
//   // const rangeChange = (item) => {
//   //   console.log("date range: ", item);
//   //   setState([item.selection]);
//   //   fetchData(item.selection);
//   // };
//   ////////////////////////////////////////////////Date Filter end//////////////////////////////////////////////

//   const renderCustomizedLabel = (props) => {
//     const { x, y, value } = props;
//     return (
//       <text x={x} y={y} dy={-10} fill="#8884d8" fontSize={12} textAnchor="middle">
//         {numeral(value).format(value >= 1000 ? '$0.0a' : '$0')}
//       </text>
//     );
//   };
//   const CustomizedAxisTick = ({ x, y, payload }) => (
//     <g transform={`translate(${x},${y})`}>
//       <text dy={16} textAnchor="middle" fill="#666">
//         {moment(payload.value, 'M').format('MMM')}
//       </text>
//     </g>
//   );
  
//   const CustomTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       const dataPoint = payload[0].payload; // Assuming there's only one data point
//       return (
//         <div style={{ backgroundColor: '#fff', border: '1px solid #ddd', padding: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
//           <p>{moment(dataPoint.month, 'M').format('MMM')}</p>
//           <p>{`Amount: ${numeral(dataPoint.total_amount).format('$0,0')}`}</p>
//         </div>
//       );
//     }
  
//     return null;
//   };
  
//   ////////////////////////////////////////////////Date Filter start//////////////////////////////////////////////
//   // const dateRangeText = () => {
//   //   if (moment(state[0]?.startDate).year() === moment(state[0]?.endDate).year()) {
//   //     if (moment(state[0]?.startDate).month() === moment(state[0]?.endDate).month()) {
//   //       return moment(state[0]?.startDate).format('MMM D') + '-' + moment(state[0]?.endDate).format('D, YYYY');
//   //     }
//   //     else {
//   //       return moment(state[0]?.startDate).format('MMM D') + '-' + moment(state[0]?.endDate).format('MMM D, YYYY');
//   //     }
//   //   }
//   //   else
//   //     return moment(state[0]?.startDate).format('MMM D, YYYY') + '-' + moment(state[0]?.endDate).format('MMM D, YYYY');
//   // };
//   ////////////////////////////////////////////////Date Filter End //////////////////////////////////////////////
//   console.log("DASHBOARD SALES",dashboardStats.salesOverview);

//   const statsWidget = (className, iconName, color, widgetTitle, count) => {
//     return (
//       <div className={`card card-custom bg-white mt-4 ${className}`}>
//         <div className="card-body position-relative overflow-hidden">
//           <div className="row justify-content-center">
//             <div className="col-md-4 my-auto">
//               <i style={{ color: color, 'fontSize': '3em' }} className={`fa fa-${iconName}`}></i>
//             </div>
//             <div className="col-md-8">
//               <div className="row">
//                 <h4>{widgetTitle}</h4>
//               </div>
//               <div className="row">
//                 <h1>{count}</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };


//   return (
//     <>
//       <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222' }}>
//         <Loader loading={loading} />
//       </LoadingOverlay>
//       {/* ////////////////////////////////////////////////Date Filter Start////////////////////////////////////////////// */}

//       {/* <div className="row">
//         <div className="col-md-12">
//           <Dropdown className="dropdown-inline calendarbox" alignRight>
//             <Dropdown.Toggle
//               className="btn btn-color-primary btn-clean btn-hover-light-primary btn-icon"
//               variant="transparent"
//               id="dropdown-toggle-top"
//               as={DropdownCustomToggler}
//             >
//               <i className="ki ki-calendar btn btn-color-primary btn-active-light-primary" />
//               {dateRangeText()}
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
//               <DefinedRange
//                 onChange={item => rangeChange(item)}
//                 ranges={state}
//                 staticRanges={rangesArr}
//               />
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//       </div> */}
// {/* 
// ////////////////////////////////////////////////Date Filter end////////////////////////////////////////////// */}

//       {/* <div className="container d-flex justify-content-center">
    
//           <div className="col-md-5 col-xxl-6">
//             <div className="row bg-light">
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'calendar-plus', "#FF8900", 'Total Events', dashboardStats ? dashboardStats.totalEvent : 0)}
//               </div>
//               <div className="col-lg-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'comment-dollar', "#BE2EB5", 'Total Revenue Earning', dashboardStats ? `$${dashboardStats.totalRevenue}` : '$0')}
//               </div>
//             </div>
//             <div className="row bg-light">
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'credit-card', "#BE2EB5", 'Total Tickets Sold', dashboardStats ? dashboardStats.ticketSold : 0)}
//               </div>
//               <div className="col-md-6 col-xxl-6">
//                 {statsWidget("card-stretch gutter-b", 'users', "#BE2EB5", 'Total Engagement', dashboardStats ? dashboardStats.totalEngage : 0)}
//               </div>
//             </div>
//           </div>
//          </div> */}
//           {/* <div className="container d-flex justify-content-center" style={{marginBottom:"0px"}}> */}
//           <div className="container d-flex justify-content-center">
//       <div >
//         <div className="row bg-light">
//           <div className="col-md-3 col-xxl-3">
//             {statsWidget("card-stretch gutter-b", 'calendar-plus', "#FF8900", 'Total Events', dashboardStats ? dashboardStats.totalEvent : 0)}
//           </div>
//           <div className="col-md-3 col-xxl-3">
//             {statsWidget("card-stretch gutter-b", 'comment-dollar', "#BE2EB5", 'Total Revenue Earning', dashboardStats ? `$${dashboardStats.totalRevenue}` : '$0')}
//           </div>
//           <div className="col-md-3 col-xxl-3">
//             {statsWidget("card-stretch gutter-b", 'credit-card', "#BE2EB5", 'Total Tickets Sold', dashboardStats ? dashboardStats.ticketSold : 0)}
//           </div>
//           <div className="col-md-3 col-xxl-3">
//             {statsWidget("card-stretch gutter-b", 'users', "#BE2EB5", 'Total Engagement', dashboardStats ? dashboardStats.totalEngage : 0)}
//           </div>
//         </div>
//       </div>
//     </div>

//           <div style={{marginLeft:"45px",marginTop:"20px"}}>
//           <div>
            
//             <h2>Sales Overview</h2>
//             {dashboardStats && (

//        <AreaChart
//   width={1000}
//   height={450}
//   data={dashboardStats.salesOverview}
//   margin={{ top: 10, right: 30, left: 10, bottom: 10 }}
// >
//   <defs>
//     <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//       <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
//       <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
//     </linearGradient>
//   </defs>
//   <XAxis dataKey="month" tick={<CustomizedAxisTick />} />
//   <YAxis
//     domain={[0, 30000]}
//     tickFormatter={(value) => (typeof value === 'number' ? numeral(value).format(value >= 1000 ? '$0.0a' : '$0') : value)}
//   />
//   <CartesianGrid strokeDasharray="3 3" />
//   <Tooltip content={<CustomTooltip />} />
//   <Legend />
//   <Area
//     type="monotone"
//     dataKey="total_amount"
//     stroke="#8884d8"
//     fill="url(#colorUv)"
//     label={renderCustomizedLabel}
//   />
// </AreaChart>


        
//             )}
//           </div>
//         </div>
//       {/* <RSVPChart/>
//       <RSVPStatus/> */}

// <RSVPStatus completed={prizes.completed} pending={prizes.pending} total={prizes.total} />

//           <NotificationMessages notifications={notifications} />
//     </>
//   );
// }
/////////////////////////////////////////////////////////////////New Updated code////////////////////////////////////////////////////////////
   
//////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  AreaChart,
} from 'recharts';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';  // Import the styles
import { Dropdown } from 'react-bootstrap';
import { DefinedRange } from 'react-date-range';
import { isSameDay } from 'date-fns';
import numeral from 'numeral';
import { Label } from 'recharts';
import axios from 'axios';
import moment from 'moment';
import { DropdownCustomToggler } from '../dropdowns';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { startOfDay, addDays, endOfDay } from 'date-fns';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';


export const DASHBOARD_URL = "adminDashboard";
const RSVPStatus = ({ completed, pending, total }) => {
  const completedPercentage = (completed / total) * 100;
  const pendingPercentage = (pending / total) * 100;

  return (
    <div className='Heading' style={{ marginLeft: '50px' }}>
      <h2>Prize Count</h2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '300px',
          marginBottom: '80px',
          marginLeft: '350px',
        }}
      >
        <div style={{ textAlign: 'left', width: '100px', marginTop: '70px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span role='img' aria-label='Check Mark' style={{ fontSize: '20px', marginRight: '5px' }}>
              ✓
            </span>
            <div>
              <strong>Completed</strong>
              <div>{completed} ({completedPercentage.toFixed(2)}%)</div>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span role='img' aria-label='Cross Mark' style={{ fontSize: '20px', marginRight: '5px' }}>
              ❌
            </span>
            <div>
              <strong>Pending</strong>
              <div>{pending} ({pendingPercentage.toFixed(2)}%)</div>
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'center', width: '200px', marginLeft: '20px', marginTop: '60px', marginTop: "5px" }}>
          <CircularProgressbar
            value={completedPercentage}
            styles={{
              path: { stroke: '#007bff', transition: 'none' },
              trail: { stroke: '#eee' },
              background: { fill: '#fff' },
              text: { fontSize: '10px', fill: '#333' },
            }}
            strokeWidth={12}
          />
          <div style={{ marginTop: '-120px', textAlign: 'center', fontSize: '18px', color: '#333' }}>
            Prizes Total<br />
            <b>{total}</b>
          </div>
        </div>
      </div>
    </div>
  );
};
///////////////////////////////////////////////////////////////Mui Tables Try notification///////////////////////////////////////////////////////


// const NotificationMessages = ({ notifications }) => {
//   const storedMonthFilter = sessionStorage.getItem('selectedMonthFilter');
//   const [selectedMonthFilter, setSelectedMonthFilter] = useState(storedMonthFilter || 'all');
//   const [filteredNotifications, setFilteredNotifications] = useState(notifications);

//   // const applyMonthFilter = (monthFilter) => {
//   //   if (monthFilter === 'all') {
//   //     const currentYear = moment().year();
//   //     const filtered = notifications.filter((notification) => {
//   //       const notificationYear = moment(notification.dateSent).year();
//   //       return notificationYear === currentYear;
//   //     });
//   //     return filtered;
//   //   } else {
//   //     const currentYear = moment().year();
//   //     const selectedMonth = parseInt(monthFilter);
//   //     const filtered = notifications.filter((notification) => {
//   //       const notificationMonth = moment(notification.dateSent).month() + 1;
//   //       const notificationYear = moment(notification.dateSent).year();
//   //       return notificationYear === currentYear && notificationMonth === selectedMonth;
//   //     });
//   //     return filtered;
//   //   }
//   // };
//   const applyMonthFilter = (monthFilter) => {
//     let filtered;
//     const currentYear = moment().year();
  
//     if (monthFilter === 'all') {
//       // Display all activities sorted by date in descending order
//       filtered = notifications.filter((notification) => {
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear;
//       });
//     } else {
//       const selectedMonth = parseInt(monthFilter);
//       // Filter by selected month
//       filtered = notifications.filter((notification) => {
//         const notificationMonth = moment(notification.dateSent).month() + 1;
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear && notificationMonth === selectedMonth;
//       });
//     }
  
//     // Sort notifications by date in descending order
//     filtered.sort((a, b) => moment(b.dateSent).valueOf() - moment(a.dateSent).valueOf());
  
//     return filtered;
//   };
  
//   const handleMonthFilterChange = (event) => {
//     const newMonthFilter = event.target.value;
//     setSelectedMonthFilter(newMonthFilter);
//   };

//   useEffect(() => {
//     const filteredByMonth = applyMonthFilter(selectedMonthFilter);
//     setFilteredNotifications(filteredByMonth);
//   }, [selectedMonthFilter, notifications]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedMonthFilter', selectedMonthFilter);
//   }, [selectedMonthFilter]);

//   const dt_options = {
//     filterType: 'checkbox',
//     selectableRows: false,
//   };

//   const dt_columns = [

//     {
//       name: 'sender',
//       label: 'Sender',
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value) => (
//           <div style={{ display: 'flex',float:"right" }}>         
//              <img
//               src={value.profilePic}
//               alt="Profile"
//               style={{
//                 width: '50px',  // Adjust the width as needed
//                 height: '50px', // Adjust the height as needed
//                 borderRadius: '50%',
//                 flexShrink: 0,
//               }}
//             />
//             {value.name}
//           </div>
//         ),
//       },
//     },
//     // {
//     //   name: 'sender',
//     //   label: 'Sender',
//     //   options: {
//     //     filter: true,
//     //     sort: false,
//     //     customBodyRender: (value) => (
//     //       <div>
//     //         <img
//     //           src={value.profilePic}
//     //           alt="Profile"
//     //           style={{
//     //             width: '30px',
//     //             height: '30px',
//     //             borderRadius: '50%',
//     //             marginRight: '10px',
//     //             flexShrink: 0,
//     //           }}
//     //         />
//     //         {value.name}
//     //       </div>
//     //     ),
//     //   },
//     // },
//     {
//       name: 'dateSent',
//      label: 'dateSent', 
//       options: {
//         filter: true,
//         sort: true,
//         display:false,
//       },
//     },
    
//     // {
//     //   name: "dateSent",
//     //   label: "Date",
//     //   options: {
//     //     filter: true,
//     //     sort: true,
//     //     display: true,
//     //     customBodyRender: (value, tableMeta) => (
//     //       <>
//     //         <p style={{ margin: 0, paddingBottom: '5px' }}>{tableMeta.rowData[1]}</p>
//     //         <p style={{ margin: 0 }}>{value ? moment(value).format('DD MMM YYYY HH:mm:ss') : ''}</p>
//     //       </>
//     //     ),
//     //   },
//     // },
//     {
//       name: 'message',
//       label: 'Message',
//       options: {
//         filter: true,
//         sort: true,
//         display: true,
//         customBodyRender: (value, tableMeta) => (
//           <>
//             <p style={{ margin: 0, paddingBottom: '5px', fontSize: '13px' }}>{value}</p>
//             <p style={{ margin: 0, fontSize: '12px' }}>{tableMeta.rowData[1] ? moment(tableMeta.rowData[1]).format('DD MMM YYYY HH:mm:ss') : ''}</p>
//           </>
//         ),
//       },
//     },
    
//     // {
//     //   name: 'dateSent',
//     //   label: 'Date Sent',
//     //   options: {
//     //     filter: true,
//     //     sort: true,
//     //     customBodyRender: (value) => moment(value).format('DD MMM YYYY HH:mm:ss'),
//     //   },
//     // },
  
//   ];
//   const theme = createMuiTheme({
//     overrides: {
//       MUIDataTable: {
//         root: { border: '1px solid #ddd'},
//         paper: {
//           boxShadow: 'none',
//           border: '1px solid #ddd', // Add border to the whole table container
//         },
//       },
//       MUIDataTableBodyRow: {
//         root: {
//           '&:nth-child(odd)': {
//             backgroundColor: '#f9f9f9',
//           },
//         },
//       },
//       MUIDataTableBodyCell: {
//         root: {
//           border: 'none', // Remove border for each cell
//         },
//       },
      
//       MuiTableCell: {
//         root: {
//           border: 'none', // Remove border for the whole table
//         },
//       },
//       MUIDataTableHeadCell: {
//         root: {
//           display: 'none', // Hide the header
          
//         },
//       },

//       MUIDataTableToolbar: {
//         root: {
//           borderBottom: '1px solid #ddd', // Add a border at the bottom of the toolbar
//         },
//       },
    
//       MuiTypography: {
//         h6: {},
//       },
//       MuiTableFooter: {
//         root: {
//           borderTop: '1px solid #ddd', // Add top border for the footer (pagination section)
//         },
//       },
//     },
//   });
  
  
//   // const theme = createMuiTheme({
//   //   overrides: {
//   //     MUIDataTable: {
//   //       root: {},
//   //       paper: {
//   //         boxShadow: 'none',
//   //       },
//   //     },
//   //     MUIDataTableBodyRow: {
//   //       root: {
//   //         '&:nth-child(odd)': {
//   //           backgroundColor: '#f9f9f9',
//   //         },
//   //       },
//   //     },
//   //     MUIDataTableBodyCell: {},
//   //     MuiTableCell: {
//   //       root: {
//   //         border: '1px solid #ccc',
//   //       },
//   //     },
//   //     MUIDataTableHeadCell: {
//   //       root: {},
//   //     },
//   //     MuiTypography: {
//   //       h6: {},
//   //     },
//   //   },
//   // });

//   return (
//     <div style={{ marginLeft: '40px' }}>
//       <div>
//         {/* Dropdown for month filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '150px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedMonthFilter}
//           onChange={handleMonthFilterChange}
//         >
//           <option value="all">All Activity</option>
//           {[...Array(12).keys()].map((month) => (
//             <option key={month} value={month + 1}>
//               {moment().month(month).format('MMMM')}
//             </option>
//           ))}
//         </select>

//         {/* Activity Feed heading */}
//         <h2 style={{ textAlign: 'left' }}>Activity Feed</h2>

//         {/* Display notifications in MUI DataTable */}
//         <MuiThemeProvider theme={theme}>
//           <MUIDataTable data={filteredNotifications} columns={dt_columns} options={dt_options} />
//         </MuiThemeProvider>
//       </div>
//     </div>
//   );
// };

// export default NotificationMessages;


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////Without page filter////////////////////////////////////////////////////////////////////////

// const NotificationMessages = ({ notifications }) => {
//   const storedMonthFilter = sessionStorage.getItem('selectedMonthFilter');
//   const [selectedMonthFilter, setSelectedMonthFilter] = useState(storedMonthFilter || 'all');

//   const [filteredNotifications, setFilteredNotifications] = useState(notifications);

//   const applyMonthFilter = (monthFilter) => {
//     if (monthFilter === 'all') {
//       const currentYear = moment().year();
//       const filtered = notifications.filter((notification) => {
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear;
//       });
//       return filtered;
//     } else {
//       const currentYear = moment().year();
//       const selectedMonth = parseInt(monthFilter);
//       const filtered = notifications.filter((notification) => {
//         const notificationMonth = moment(notification.dateSent).month() + 1;
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear && notificationMonth === selectedMonth;
//       });
//       return filtered;
//     }
//   };

//   const handleMonthFilterChange = (event) => {
//     const newMonthFilter = event.target.value;
//     setSelectedMonthFilter(newMonthFilter);
//   };

//   useEffect(() => {
//     const filteredByMonth = applyMonthFilter(selectedMonthFilter);
//     setFilteredNotifications(filteredByMonth);
//   }, [selectedMonthFilter, notifications]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedMonthFilter', selectedMonthFilter);
//   }, [selectedMonthFilter]);

//   return (
//     <div style={{ marginLeft: '40px' }}>
//       <div>
//         {/* Dropdown for month filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '150px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedMonthFilter}
//           onChange={handleMonthFilterChange}
//         >
//           <option value="all">All Activity</option>
//           {[...Array(12).keys()].map((month) => (
//             <option key={month} value={month + 1}>
//               {moment().month(month).format('MMMM')}
//             </option>
//           ))}
//         </select>

//         {/* Activity Feed heading */}
//         <h2 style={{ textAlign: 'left' }}>Activity Feed</h2>

//         {/* Display notifications */}
//         {filteredNotifications.map((notification, index) => (
//           <div
//             key={notification.id}
//             className="notification-item d-flex align-items-start"
//             style={{
//               marginBottom: index === filteredNotifications.length - 1 ? 0 : '10px',
//               marginTop: '20px',
//             }}
//           >
//             <img
//               src={notification.sender.profilePic}
//               alt="Profile"
//               style={{
//                 width: '50px',
//                 height: '50px',
//                 borderRadius: '50%',
//                 marginRight: '10px',
//                 flexShrink: 0,
//               }}
//             />
//             <div>
//               <p style={{ margin: 0, paddingBottom: '5px' }}>{notification.message}</p>
//               <p style={{ margin: 0 }}>{moment(notification.dateSent).format('DD MMM YYYY HH:mm:ss')}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };



///////////////////////////////////////////////////////////////advanced pagination/////////////////////////////////////////////////

// const NotificationMessages = ({ notifications }) => {
//   const storedMonthFilter = sessionStorage.getItem('selectedMonthFilter');
//   const [selectedMonthFilter, setSelectedMonthFilter] = useState(storedMonthFilter || 'all');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10); // You can adjust the number of items per page

//   const [filteredNotifications, setFilteredNotifications] = useState(notifications);

//   const applyMonthFilter = (monthFilter) => {
//     if (monthFilter === 'all') {
//       const currentYear = moment().year();
//       const filtered = notifications.filter((notification) => {
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear;
//       });
//       return filtered;
//     } else {
//       const currentYear = moment().year();
//       const selectedMonth = parseInt(monthFilter);
//       const filtered = notifications.filter((notification) => {
//         const notificationMonth = moment(notification.dateSent).month() + 1;
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear && notificationMonth === selectedMonth;
//       });
//       return filtered;
//     }
//   };

//   const handleMonthFilterChange = (event) => {
//     const newMonthFilter = event.target.value;
//     setSelectedMonthFilter(newMonthFilter);
//     setCurrentPage(1); // Reset to the first page when the month filter changes
//   };

//   useEffect(() => {
//     const filteredByMonth = applyMonthFilter(selectedMonthFilter);
//     setFilteredNotifications(filteredByMonth);
//   }, [selectedMonthFilter, notifications]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedMonthFilter', selectedMonthFilter);
//   }, [selectedMonthFilter]);

//   // Get current notifications based on pagination
//   const indexOfLastNotification = currentPage * itemsPerPage;
//   const indexOfFirstNotification = indexOfLastNotification - itemsPerPage;
//   const currentNotifications = filteredNotifications.slice(indexOfFirstNotification, indexOfLastNotification);

//   return (
//     <div style={{ marginLeft: '40px' }}>
//       <div>
//         {/* Dropdown for month filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '150px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedMonthFilter}
//           onChange={handleMonthFilterChange}
//         >
//           <option value="all">All Activity</option>
//           {[...Array(12).keys()].map((month) => (
//             <option key={month} value={month + 1}>
//               {moment().month(month).format('MMMM')}
//             </option>
//           ))}
//         </select>

//         {/* Activity Feed heading */}
//         <h2 style={{ textAlign: 'left' }}>Activity Feed</h2>

//         {/* Display current page notifications */}
//         {currentNotifications.map((notification, index) => (
//           <div
//             key={notification.id}
//             className="notification-item d-flex align-items-start"
//             style={{
//               marginBottom: index === currentNotifications.length - 1 ? 0 : '10px',
//               marginTop: '20px',
//             }}
//           >
//             <img
//               src={notification.sender.profilePic}
//               alt="Profile"
//               style={{
//                 width: '50px',
//                 height: '50px',
//                 borderRadius: '50%',
//                 marginRight: '10px',
//                 flexShrink: 0,
//               }}
//             />
//             <div>
//               <p style={{ margin: 0, paddingBottom: '5px' }}>{notification.message}</p>
//               <p style={{ margin: 0 }}>{moment(notification.dateSent).format('DD MMM YYYY HH:mm:ss')}</p>
//             </div>
//           </div>
//         ))}

//         {/* Pagination */}
//         <div style={{ marginTop: '20px', textAlign: 'center' }}>
//           <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
//             Previous Page
//           </button>
//           <span style={{ margin: '0 10px' }}>Page {currentPage}</span>
//           <button
//             onClick={() => setCurrentPage(currentPage + 1)}
//             disabled={indexOfLastNotification >= filteredNotifications.length}
//           >
//             Next Page
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NotificationMessages;


/////////////////////////////////////////////////Current data with Pagesize or month filter/////////////////////////////////////////////
// const NotificationMessages = ({ notifications }) => {
//   const storedMonthFilter = sessionStorage.getItem('selectedMonthFilter');
//   const storedPageSizeFilter = sessionStorage.getItem('selectedPageSizeFilter');

//   const [selectedMonthFilter, setSelectedMonthFilter] = useState(
//     storedMonthFilter || 'all'
//   );
//   const [selectedPageSizeFilter, setSelectedPageSizeFilter] = useState(
//     storedPageSizeFilter || -1
//   );

//   const [filteredNotifications, setFilteredNotifications] = useState(notifications);

//   const applyMonthFilter = (monthFilter) => {
//     if (monthFilter === 'all') {
//       const currentYear = moment().year();
//       const filtered = notifications.filter((notification) => {
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear;
//       });
//       return filtered;
//     } else {
//       const currentYear = moment().year();
//       const selectedMonth = parseInt(monthFilter);
//       const filtered = notifications.filter((notification) => {
//         const notificationMonth = moment(notification.dateSent).month() + 1;
//         const notificationYear = moment(notification.dateSent).year();
//         return notificationYear === currentYear && notificationMonth === selectedMonth;
//       });
//       return filtered;
//     }
//   };
  

//   const applyPageSizeFilter = (notifications, pageSize) => {
//     return pageSize === -1 ? notifications : notifications.slice(0, pageSize);
//   };

//   const handleMonthFilterChange = (event) => {
//     const newMonthFilter = event.target.value;
//     setSelectedMonthFilter(newMonthFilter);
//   };

//   const handlePageSizeFilterChange = (event) => {
//     const newPageSizeFilter = parseInt(event.target.value);
//     setSelectedPageSizeFilter(newPageSizeFilter);
//   };

//   useEffect(() => {
//     const filteredByMonth = applyMonthFilter(selectedMonthFilter);
//     const finalFilteredNotifications = applyPageSizeFilter(
//       filteredByMonth,
//       selectedPageSizeFilter
//     );
//     setFilteredNotifications(finalFilteredNotifications);
//   }, [selectedMonthFilter, selectedPageSizeFilter, notifications]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedMonthFilter', selectedMonthFilter);
//   }, [selectedMonthFilter]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedPageSizeFilter', selectedPageSizeFilter);
//   }, [selectedPageSizeFilter]);

//   return (
//     <div style={{ marginLeft: '40px' }}>
//       <div>
//         {/* Dropdown for month filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '150px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedMonthFilter}
//           onChange={handleMonthFilterChange}
//         >
//           <option value="all">All Activity</option>
//           {[...Array(12).keys()].map((month) => (
//             <option key={month} value={month + 1}>
//               {moment().month(month).format('MMMM')}
//             </option>
//           ))}
//         </select>

//         {/* Dropdown for page size filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '100px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedPageSizeFilter}
//           onChange={handlePageSizeFilterChange}
//         >
//           <option value={-1}>All</option>
//           <option value={10}>10</option>
//           <option value={50}>50</option>
//           <option value={100}>100</option>
//           <option value={150}>150</option>
//           <option value={200}>200</option>
//         </select>

//         {/* Activity Feed heading */}
//         <h2 style={{ textAlign: 'left' }}>Activity Feed</h2>

//         {/* Display notifications */}
//         {filteredNotifications.map((notification, index) => (
//           <div
//             key={notification.id}
//             className="notification-item d-flex align-items-start"
//             style={{
//               marginBottom: index === filteredNotifications.length - 1 ? 0 : '10px',
//               marginTop: '20px',
//             }}
//           >
//             <img
//               src={notification.sender.profilePic}
//               alt="Profile"
//               style={{
//                 width: '50px',
//                 height: '50px',
//                 borderRadius: '50%',
//                 marginRight: '10px',
//                 flexShrink: 0,
//               }}
//             />
//             <div>
//               <p style={{ margin: 0, paddingBottom: '5px' }}>{notification.message}</p>
//               <p style={{ margin: 0 }}>{moment(notification.dateSent).format('DD MMM YYYY HH:mm:ss')}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
//////////////////////////////////////////////////////////////Last Year FIlters/////////////////////////////////////////////////////////////////////////////////
// const NotificationMessages = ({ notifications }) => {
//   const storedMonthFilter = sessionStorage.getItem('selectedMonthFilter');
//   const storedYearFilter = sessionStorage.getItem('selectedYearFilter');
//   const storedPageSizeFilter = sessionStorage.getItem('selectedPageSizeFilter');

//   const [selectedMonthFilter, setSelectedMonthFilter] = useState(
//     storedMonthFilter || 'all'
//   );
//   const [selectedYearFilter, setSelectedYearFilter] = useState(
//     storedYearFilter || moment().year()
//   );
//   const [selectedPageSizeFilter, setSelectedPageSizeFilter] = useState(
//     storedPageSizeFilter || -1
//   );

//   const [filteredNotifications, setFilteredNotifications] = useState(notifications);

  
//   const applyMonthFilter = (yearFilter, monthFilter) => {
//     if (monthFilter === 'all') {
//       return yearFilter === 'all'
//         ? notifications
//         : notifications.filter((notification) => {
//             const notificationYear = moment(notification.dateSent).year();
//             return notificationYear === parseInt(yearFilter);
//           });
//     } else {
//       const selectedMonth = parseInt(monthFilter);
//       const selectedYear = yearFilter === 'all' ? null : parseInt(yearFilter);

//       const filtered = notifications.filter((notification) => {
//         const notificationMonth = moment(notification.dateSent).month() + 1;
//         const notificationYear = moment(notification.dateSent).year();

//         return (
//           (selectedYear === null || notificationYear === selectedYear) &&
//           notificationMonth === selectedMonth
//         );
//       });
//       return filtered;
//     }
//   };


//   const applyPageSizeFilter = (notifications, pageSize) => {
//     return pageSize === -1 ? notifications : notifications.slice(0, pageSize);
//   };

//   const handleMonthFilterChange = (event) => {
//     const newMonthFilter = event.target.value;
//     setSelectedMonthFilter(newMonthFilter);
//   };

//   const handleYearFilterChange = (event) => {
//     const newYearFilter = parseInt(event.target.value);
//     setSelectedYearFilter(newYearFilter);
//   };

//   const handlePageSizeFilterChange = (event) => {
//     const newPageSizeFilter = parseInt(event.target.value);
//     setSelectedPageSizeFilter(newPageSizeFilter);
//   };

//   useEffect(() => {
//     const filteredByMonth = applyMonthFilter(selectedYearFilter, selectedMonthFilter);
//     const finalFilteredNotifications = applyPageSizeFilter(
//       filteredByMonth,
//       selectedPageSizeFilter
//     );
//     setFilteredNotifications(finalFilteredNotifications);
//   }, [selectedYearFilter, selectedMonthFilter, selectedPageSizeFilter, notifications]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedMonthFilter', selectedMonthFilter);
//   }, [selectedMonthFilter]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedYearFilter', selectedYearFilter);
//   }, [selectedYearFilter]);

//   useEffect(() => {
//     sessionStorage.setItem('selectedPageSizeFilter', selectedPageSizeFilter);
//   }, [selectedPageSizeFilter]);

//   return (
//     <div style={{ marginLeft: '40px' }}>
//       <div>
//         {/* Dropdown for year filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '100px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedYearFilter}
//           onChange={handleYearFilterChange}
//         >
//           {[...Array(5).keys()].map((index) => (
//             <option key={index} value={moment().year() - index}>
//               {moment().year() - index}
//             </option>
//           ))}
//         </select>

//         {/* Dropdown for month filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '150px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedMonthFilter}
//           onChange={handleMonthFilterChange}
//         >
//           <option value="all">All Activity</option>
//           {[...Array(12).keys()].map((month) => (
//             <option key={month} value={month + 1}>
//               {moment().month(month).format('MMMM')}
//             </option>
//           ))}
//         </select>

//         {/* Dropdown for page size filter */}
//         <select
//           style={{
//             float: 'right',
//             width: '100px',
//             height: '30px',
//             flexShrink: 0,
//             borderRadius: '50px',
//             background: '#E8F1F9',
//             color: '#333',
//             textAlign: 'center',
//             fontFamily: 'Poppins',
//             fontSize: '12px',
//             fontStyle: 'normal',
//             fontWeight: 500,
//             lineHeight: 'normal',
//             marginLeft: '10px',
//           }}
//           value={selectedPageSizeFilter}
//           onChange={handlePageSizeFilterChange}
//         >
//           <option value={-1}>All</option>
//           <option value={10}>10</option>
//           <option value={50}>50</option>
//           <option value={100}>100</option>
//           <option value={150}>150</option>
//           <option value={200}>200</option>
//         </select>

//         {/* Activity Feed heading */}
//         <h2 style={{ textAlign: 'left' }}>Activity Feed</h2>

//         {/* Display notifications */}
//         {filteredNotifications.map((notification, index) => (
//           <div
//             key={notification.id}
//             className="notification-item d-flex align-items-start"
//             style={{
//               marginBottom: index === filteredNotifications.length - 1 ? 0 : '10px',
//               marginTop: '20px',
//             }}
//           >
//             <img
//               src={notification.sender.profilePic}
//               alt="Profile"
//               style={{
//                 width: '50px',
//                 height: '50px',
//                 borderRadius: '50%',
//                 marginRight: '10px',
//                 flexShrink: 0,
//               }}
//             />
//             <div>
//               <p style={{ margin: 0, paddingBottom: '5px' }}>{notification.message}</p>
//               <p style={{ margin: 0 }}>{moment(notification.dateSent).format('DD MMM YYYY HH:mm:ss')}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };




export function Demo1Dashboard() {
  const [dashboardStats, setDashboardStats] = useState(false);
  const [loading, setLoader] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [notifications, setNotifications] = useState([]); // Add this line
const [prizes, setPrizes] = useState({ total: 0, completed: 0, pending: 0 });
const [filter, setFilter] = useState('yearly'); // Initial filter

const fetchData = async () => {
  setLoader(true);

  try {
    const selectionMapping = {
      yearly: 'year',
      monthly: 'month',
      weekly: 'week',
    };

    const response = await axios.post(DASHBOARD_URL, { selection: selectionMapping[filter] });
    console.log("Data from the server:", response.data);

    // Check if the data property exists in the response
    if (response.data.data) {
      setDashboardStats({
        salesOverview: response.data.data.transactions,
        totalEvent: response.data.data.totalEvent,
        totalRevenue: response.data.data.totalRevenue,
        ticketSold: response.data.data.ticketSold,
        totalEngage: response.data.data.totalEngage,
      });

      // Set notifications here
      setNotifications(response.data.data.notification || []);

      // Set prizes data
      setPrizes(response.data.data.prizes || { total: 0, completed: 0, pending: 0 });
    } else {
      console.error("Invalid data structure received from the server.");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    setErrors(error);
  } finally {
    setLoader(false);
  }
};

useEffect(() => {
  fetchData();
}, [filter]);

const renderCustomizedLabel = (props) => {
  const { x, y, value } = props;
  return (
    <text x={x} y={y} dy={-10} fill="#8884d8" fontSize={12} textAnchor="middle">
      {numeral(value).format(value >= 1000 ? '$0.0a' : '$0')}
    </text>
  );
};

const CustomizedAxisTick = ({ x, y, payload }) => {
  let formattedValue = payload.value;

  if (filter === 'yearly') {
    // Convert month number to MMM format
    formattedValue = moment().month(payload.value - 1).format('MMM');
  } else if (filter === 'monthly') {
    // Display week number
    formattedValue = `Week ${payload.value}`;
  } else if (filter === 'weekly') {
    // Display day name
    formattedValue = moment(payload.value, 'YYYY-MM-DD').format('dddd');
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor="middle" fill="#666">
        {formattedValue}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload; // Assuming there's only one data point
    let formattedDate = dataPoint.day;

    if (filter === 'yearly') {
      // Display month and full year for yearly data
      formattedDate = `${moment(dataPoint.month, 'M').format('MMM')} ${moment(dataPoint.day).format('YYYY')}`;
    } else if (filter === 'monthly') {
      // Display week number and month name for monthly data
      formattedDate = `Week ${dataPoint.week}, ${moment(dataPoint.day).format('MMM')}`;
    } else if (filter === 'weekly') {
      // Display full date for weekly data
      formattedDate = moment(dataPoint.day).format('MMM D, YYYY');
    }

    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ddd', padding: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <p>{formattedDate}</p>
        <p>{`Amount: ${numeral(dataPoint.total_amount).format('$0,0')}`}</p>
      </div>
    );
  }

  return null;
};


const handleFilterChange = (selectedFilter) => {
  setFilter(selectedFilter);
};

console.log("DASHBOARD SALES",dashboardStats.salesOverview);

  const statsWidget = (className, iconName, color, widgetTitle, count) => {
    return (
      <div className={`card card-custom bg-white mt-4 ${className}`}>
        <div className="card-body position-relative overflow-hidden">
          <div className="row justify-content-center">
            <div className="col-md-4 my-auto">
              <i style={{ color: color, 'fontSize': '3em' }} className={`fa fa-${iconName}`}></i>
            </div>
            <div className="col-md-8">
              <div className="row">
                <h4>{widgetTitle}</h4>
              </div>
              <div className="row">
                <h1>{count}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222' }}>
        <Loader loading={loading} />
      </LoadingOverlay>
  <div className="container d-flex justify-content-left" style={{marginLeft:"-1px"}}>
      <div >
        <div className="row bg-light">
          <div className="col-md-3 col-xxl-3">
            {statsWidget("card-stretch gutter-b", 'calendar-plus', "#FF8900", 'Total Events', dashboardStats ? dashboardStats.totalEvent : 0)}
          </div>
          <div className="col-md-3 col-xxl-3">
            {statsWidget("card-stretch gutter-b", 'comment-dollar', "#BE2EB5", 'Total Revenue Earning', dashboardStats ? `$${dashboardStats.totalRevenue}` : '$0')}
          </div>
          <div className="col-md-3 col-xxl-3">
            {statsWidget("card-stretch gutter-b", 'credit-card', "#BE2EB5", 'Total Tickets Sold', dashboardStats ? dashboardStats.ticketSold : 0)}
          </div>
          <div className="col-md-3 col-xxl-3">
            {statsWidget("card-stretch gutter-b", 'users', "#BE2EB5", 'Total Engagement', dashboardStats ? dashboardStats.totalEngage : 0)}
          </div>
        </div>
      </div>
    </div>
    <div style={{marginTop:"10px"}}>
    <div>
      <h2>Sales Overview</h2>
        <div>
    <select  style={{
      float:"right",
        width: '146px',
        height: '30px',
        flexShrink: 0,
        borderRadius: '50px',
        background: '#E8F1F9',
        color: '#333',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
      }}
    value={filter} onChange={(e) => handleFilterChange(e.target.value)}>
      <option value="yearly">This Yearly</option>
      <option value="monthly">This Monthly</option>
      <option value="weekly">This Weekly</option>
    </select>
  </div>

      {dashboardStats && (
        <AreaChart
          width={1000}
          height={450}
          data={dashboardStats.salesOverview}
          margin={{ top: 10, right: 30, left: 10, bottom: 10 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey={filter === 'yearly' ? 'month' : (filter === 'monthly' ? 'week' : 'day')} tick={<CustomizedAxisTick />} />
          <YAxis
            domain={[0, 30000]}
            tickFormatter={(value) => (typeof value === 'number' ? numeral(value).format(value >= 1000 ? '$0.0a' : '$0') : value)}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Area
            type="monotone"
            dataKey="total_amount"
            stroke="#8884d8"
            fill="url(#colorUv)"
            label={renderCustomizedLabel}
          />
        </AreaChart>
      )}
    </div>
 </div>

<RSVPStatus completed={prizes.completed} pending={prizes.pending} total={prizes.total} />
          {/* <NotificationMessages notifications={notifications} /> */}
    </>
  );
}