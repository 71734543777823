export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (window.location.href.indexOf("localhost") > -1)
        // config.baseURL = "http://allez.local/api/admin/";

        config.baseURL =  "https://raffle.iambrands.com/api/admin/"; 

        //  "http://192.168.10.6:8081/api/admin/";


      // https://mogul.iambrands.com/api/admin/

      else
        config.baseURL = "/api/admin/";
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 403) {
      window.location = '/logout';
    }
    return error;
  });
}
