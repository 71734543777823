// import React from "react";
// import {Link} from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// export function Topbar() {

  

//   return (
//     <div className="topbar">
//       <div className="row">
//       <Link to="/logout" className="font-weight-bold my-auto ">
//               <FontAwesomeIcon icon="sign-out-alt"  size="lg" color="black"/> <span className="px-2 text-dark">Logout</span>
//               </Link>
//       </div>
//     </div>
//   );
// }

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
// import NotificationMessages from "./NotificationMessages";

// Make sure to import the required styles as well
import '@fortawesome/fontawesome-svg-core/styles.css';

// Configure FontAwesome library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faBell, faSignOutAlt);

export function Topbar() {
  return (
    <div className="topbar">
      <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Wrap the notification icon with Link */}
        <Link to="/notifications" style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '8px' }}>
            <FontAwesomeIcon icon="bell" size="2x" color="black" />
            
          </div>
          <span className="text-dark" style={{marginRight:"15px"}}>Notifications</span>
        </Link>
        <Link to="/logout" className="font-weight-bold my-auto" style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon="sign-out-alt" size="lg" color="black" />
          <span className="px-2 text-dark">Logout</span>
        </Link>
      </div>
    </div>
  );
}