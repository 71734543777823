import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      headerLogo: toAbsoluteUrl(`/media/logos/logo.jpg`),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  // Define the desired width and height values for the logo
  const logoWidth = "100px";
  const logoHeight = "auto"; // To maintain the aspect ratio

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand mx-auto my-4 flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img
            alt="logo"
            src={layoutProps.headerLogo}
            style={{ width: logoWidth, height: logoHeight }}
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
