import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

export const DASHBOARD_URL = "adminDashboard";

const NotificationList = ({ }) => {
  const storedMonthFilter = sessionStorage.getItem('selectedMonthFilter');
  const initialSelectedMonthFilter = storedMonthFilter || 'all';
  const [selectedMonthFilter, setSelectedMonthFilter] = useState(initialSelectedMonthFilter);
  const [filteredNotifications, setFilteredNotifications] = useState([]); 
  const [loading, setLoading] = useState(true);
 
  const history = useHistory();

  const applyMonthFilter = (monthFilter) => {
    let filtered;
    const currentYear = moment().year();
  
    if (monthFilter === 'all') {
      // Display all activities sorted by date in descending order
      filtered = notifications.filter((notification) => {
        const notificationYear = moment(notification.dateSent).year();
        return notificationYear === currentYear;
      });
    } else {
      const selectedMonth = parseInt(monthFilter);
      // Filter by selected month
      filtered = notifications.filter((notification) => {
        const notificationMonth = moment(notification.dateSent).month() + 1;
        const notificationYear = moment(notification.dateSent).year();
        return notificationYear === currentYear && notificationMonth === selectedMonth;
      });
    }
  
    filtered.sort((a, b) => moment(b.dateSent).valueOf() - moment(a.dateSent).valueOf());
  
    return filtered;
  };
  
  const handleMonthFilterChange = (event) => {
    const newMonthFilter = event.target.value;
    setSelectedMonthFilter(newMonthFilter);
  };

  useEffect(() => {
    const filteredByMonth = applyMonthFilter(selectedMonthFilter);
    setFilteredNotifications(filteredByMonth);
  }, [selectedMonthFilter, notifications]);

  useEffect(() => {
    sessionStorage.setItem('selectedMonthFilter', selectedMonthFilter);
  }, [selectedMonthFilter]);

  const dt_options = {
    filterType: 'checkbox',
    selectableRows: false,
  };
  const [notifications, setNotifications] = useState([]); // Initialize state for notifications

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(DASHBOARD_URL);
        console.log("Data from the server:", response.data);

        if (response.data.data) {
          setNotifications(response.data.data.notification || []);
        } else {
          console.error("Invalid data structure received from the server.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Apply filter when selectedMonthFilter changes
    const filteredByMonth = applyMonthFilter(selectedMonthFilter);
    setFilteredNotifications(filteredByMonth);
  }, [selectedMonthFilter, notifications]);

  useEffect(() => {
    // Save selectedMonthFilter to sessionStorage when it changes
    sessionStorage.setItem('selectedMonthFilter', selectedMonthFilter);
  }, [selectedMonthFilter]);


  useEffect(() => {
    handleMonthFilterChange({ target: { value: initialSelectedMonthFilter } });
  }, [initialSelectedMonthFilter]);


  const dt_columns = [

    {
      name: 'sender',
      label: 'Sender',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ display: 'flex',float:"right" }}>         
             <img
              src={value.profilePic}
              alt="Profile"
              style={{
                width: '50px',  // Adjust the width as needed
                height: '50px', // Adjust the height as needed
                borderRadius: '50%',
                flexShrink: 0,
              }}
            />
            {value.name}
          </div>
        ),
      },
    },
 
    {
      name: 'dateSent',
     label: 'dateSent', 
      options: {
        filter: true,
        sort: true,
        display:false,
      },
    },
    
 
    {
      name: 'message',
      label: 'Message',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <>
            <p style={{ margin: 0, paddingBottom: '5px', fontSize: '13px' }}>{value}</p>
            <p style={{ margin: 0, fontSize: '12px' }}>{tableMeta.rowData[1] ? moment(tableMeta.rowData[1]).format('DD MMM YYYY HH:mm:ss') : ''}</p>
          </>
        ),
      },
    },
    
  
  
  ];
  const theme = createMuiTheme({
    overrides: {
      MUIDataTable: {
        root: { border: '1px solid #ddd'},
        paper: {
          boxShadow: 'none',
          border: '1px solid #ddd', // Add border to the whole table container
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#f9f9f9',
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          border: 'none', // Remove border for each cell
        },
      },
      
      MuiTableCell: {
        root: {
          border: 'none', // Remove border for the whole table
        },
      },
      MUIDataTableHeadCell: {
        root: {
          display: 'none', // Hide the header
          
        },
      },

      MUIDataTableToolbar: {
        root: {
          borderBottom: '1px solid #ddd', // Add a border at the bottom of the toolbar
        },
      },
    
      MuiTypography: {
        h6: {},
      },
      MuiTableFooter: {
        root: {
          borderTop: '1px solid #ddd', // Add top border for the footer (pagination section)
        },
      },
    },
  });
  
  



return (
  
    // <div>
    // <div style={{marginBottom:"30px",marginTop:"-10px"}}>
    //   <Button variant="contained" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
    //     Back
    //   </Button>
    // </div>
<div>
      <Button style={{marginBottom:"50px"}} variant="contained" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
Back
</Button>

      <div>
        {/* Dropdown for month filter */}
        <select
          style={{
            float: 'right',
            width: '150px',
            height: '30px',
            flexShrink: 0,
            borderRadius: '50px',
            background: '#E8F1F9',
            color: '#333',
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            marginLeft: '10px',
          }}
          value={selectedMonthFilter}
          onChange={handleMonthFilterChange}
        >
          <option value="all">All Activity</option>
          {[...Array(12).keys()].map((month) => (
            <option key={month} value={month + 1}>
              {moment().month(month).format('MMMM')}
            </option>
          ))}
        </select>

        {/* Activity Feed heading */}
        <h2 style={{ textAlign: 'left' }}>Activity Feed</h2>

        <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222' }}>



          <Loader loading={loading} />
          <MuiThemeProvider theme={theme}>
            <MUIDataTable data={filteredNotifications} columns={dt_columns} options={dt_options} />
          </MuiThemeProvider>
        </LoadingOverlay>
      </div>
    </div>
  );
};


export default NotificationList;